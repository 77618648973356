import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import { TitlePage } from '@styles/styles.global';

import {
  Container,
  Content,
  CompartmentContainer,
  Compartment,
  IconsRow,

  TitleRow,

} from './FooterStyle';

import LogoCLickDoBem from '@assets/Login/sociallis-logo-hor2.png';

import Logo from '../assets/logo.svg';

export default function Footer () {
  return (
      <Container>
        <Content style={{paddingTop: '1rem'}}>
          <CompartmentContainer >

          <Grid container alignItems="center" justify="center">
              <Grid container item lg={6} sm={6} xs={12} alignItems="center" justify="center" >
                <p>Dúvidas e mais informações:</p>
                <p><strong> Central de Relacionamento com o Doador (88) 99962-7536</strong></p>
                <p> E-mail: c.romeirodefatima@gmail.com </p>
                <p> <strong>Horário de Funcionamento:</strong> Segunda a Sexta-feira das 9h às 17h</p>
                <p>                               </p>
                <p><strong>Política de Privacidade</strong></p>

              </Grid>
            </Grid>

              <Compartment>

            </Compartment>
          </CompartmentContainer>
        </Content>

      </Container>
  );
}
